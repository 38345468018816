'use strict';

var Methods = {
  pluralize: function (word, locale) {
    locale = locale || 'en';
    return this._applyInflections(word, this.inflections(locale).plurals);
  },
  singularize: function (word, locale) {
    locale = locale || 'en';
    return this._applyInflections(word, this.inflections(locale).singulars);
  },
  camelize: function (term, uppercaseFirstLetter) {
    if (uppercaseFirstLetter === null || uppercaseFirstLetter === undefined) {
      uppercaseFirstLetter = true;
    }
    var result = '' + term,
      self = this;
    if (uppercaseFirstLetter) {
      result = result.replace(/^[a-z\d]*/, function (a) {
        return self.inflections().acronyms[a] || self.capitalize(a);
      });
    } else {
      result = result.replace(new RegExp('^(?:' + this.inflections().acronymRegex.source + '(?=\\b|[A-Z_])|\\w)'), function (a) {
        return a.toLowerCase();
      });
    }
    result = result.replace(/(?:_|(\/))([a-z\d]*)/gi, function (match, a, b, idx, string) {
      a || (a = '');
      return '' + a + (self.inflections().acronyms[b] || self.capitalize(b));
    });
    return result;
  },
  underscore: function (camelCasedWord) {
    var result = '' + camelCasedWord;
    result = result.replace(new RegExp('(?:([A-Za-z\\d])|^)(' + this.inflections().acronymRegex.source + ')(?=\\b|[^a-z])', 'g'), function (match, $1, $2) {
      return '' + ($1 || '') + ($1 ? '_' : '') + $2.toLowerCase();
    });
    result = result.replace(/([A-Z\d]+)([A-Z][a-z])/g, '$1_$2');
    result = result.replace(/([a-z\d])([A-Z])/g, '$1_$2');
    result = result.replace(/-/g, '_');
    return result.toLowerCase();
  },
  humanize: function (lowerCaseAndUnderscoredWord, options) {
    var result = '' + lowerCaseAndUnderscoredWord;
    var humans = this.inflections().humans;
    var human, rule, replacement;
    var self = this;
    options = options || {};
    if (options.capitalize === null || options.capitalize === undefined) {
      options.capitalize = true;
    }
    for (var i = 0, ii = humans.length; i < ii; i++) {
      human = humans[i];
      rule = human[0];
      replacement = human[1];
      if (rule.test && rule.test(result) || result.indexOf(rule) > -1) {
        result = result.replace(rule, replacement);
        break;
      }
    }
    result = result.replace(/_id$/, '');
    result = result.replace(/_/g, ' ');
    result = result.replace(/([a-z\d]*)/gi, function (match) {
      return self.inflections().acronyms[match] || match.toLowerCase();
    });
    if (options.capitalize) {
      result = result.replace(/^\w/, function (match) {
        return match.toUpperCase();
      });
    }
    return result;
  },
  capitalize: function (str) {
    var result = str === null || str === undefined ? '' : String(str);
    return result.charAt(0).toUpperCase() + result.slice(1);
  },
  titleize: function (word) {
    return this.humanize(this.underscore(word)).replace(/(^|[\s¿\/]+)([a-z])/g, function (match, boundary, letter, idx, string) {
      return match.replace(letter, letter.toUpperCase());
    });
  },
  tableize: function (className) {
    return this.pluralize(this.underscore(className));
  },
  classify: function (tableName) {
    return this.camelize(this.singularize(tableName.replace(/.*\./g, '')));
  },
  dasherize: function (underscoredWord) {
    return underscoredWord.replace(/_/g, '-');
  },
  foreignKey: function (className, separateWithUnderscore) {
    if (separateWithUnderscore === null || separateWithUnderscore === undefined) {
      separateWithUnderscore = true;
    }
    return this.underscore(className) + (separateWithUnderscore ? '_id' : 'id');
  },
  ordinal: function (number) {
    var absNumber = Math.abs(Number(number));
    var mod100 = absNumber % 100;
    if (mod100 === 11 || mod100 === 12 || mod100 === 13) {
      return 'th';
    } else {
      switch (absNumber % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
  },
  ordinalize: function (number) {
    return '' + number + this.ordinal(number);
  },
  transliterate: function (string, options) {
    options = options || {};
    var locale = options.locale || 'en';
    var replacement = options.replacement || '?';
    return this.transliterations(locale).transliterate(string, replacement);
  },
  parameterize: function (string, options) {
    options = options || {};
    if (options.separator === undefined) {
      options.separator = '-';
    }
    if (options.separator === null) {
      options.separator = '';
    }

    // replace accented chars with their ascii equivalents
    var result = this.transliterate(string, options);
    result = result.replace(/[^a-z0-9\-_]+/ig, options.separator);
    if (options.separator.length) {
      var separatorRegex = new RegExp(options.separator);

      // no more than one of the separator in a row
      result = result.replace(new RegExp(separatorRegex.source + '{2,}'), options.separator);

      // remove leading/trailing separator
      result = result.replace(new RegExp('^' + separatorRegex.source + '|' + separatorRegex.source + '$', 'i'), '');
    }
    return result.toLowerCase();
  },
  _applyInflections: function (word, rules) {
    var result = '' + word,
      rule,
      regex,
      replacement;
    if (result.length === 0) {
      return result;
    } else {
      var match = result.toLowerCase().match(/\b\w+$/);
      if (match && this.inflections().uncountables.indexOf(match[0]) > -1) {
        return result;
      } else {
        for (var i = 0, ii = rules.length; i < ii; i++) {
          rule = rules[i];
          regex = rule[0];
          replacement = rule[1];
          if (result.match(regex)) {
            result = result.replace(regex, replacement);
            break;
          }
        }
        return result;
      }
    }
  }
};
module.exports = Methods;