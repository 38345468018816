'use strict';

var Inflections = require('./Inflections');
var Transliterator = require('./Transliterator');
var Methods = require('./Methods');
var defaults = require('./defaults');
var isFunc = require('./isFunc');
var Inflector = Methods;
Inflector.inflections = function (locale, fn) {
  if (isFunc(locale)) {
    fn = locale;
    locale = null;
  }
  locale = locale || 'en';
  if (fn) {
    fn(Inflections.getInstance(locale));
  } else {
    return Inflections.getInstance(locale);
  }
};
Inflector.transliterations = function (locale, fn) {
  if (isFunc(locale)) {
    fn = locale;
    locale = null;
  }
  locale = locale || 'en';
  if (fn) {
    fn(Transliterator.getInstance(locale));
  } else {
    return Transliterator.getInstance(locale);
  }
};
for (var locale in defaults) {
  Inflector.inflections(locale, defaults[locale]);
}
module.exports = Inflector;